<template>
  <footer class="footer">
    <div data-aos="fade-up">
      <div class="container">
        <div class="footer__top">
          <div class="footer-logo">
            <router-link :to="$localePath({ name: 'Home' })">
              <img :src="require('@/assets/img/logo.svg')" alt="" />
            </router-link>
          </div>
          <div class="footer-btn-block">
            <p>
              <span class="ic-mail"></span>
              <a
                class="color-white"
                target="_blank"
                href="mailto:support@omnytraders.com"
              >
                support@omnytraders.com
              </a>
            </p>
            <p>
              <span class="ic-telegram"></span>
              <a
                class="color-white"
                target="_blank"
                href="https://t.me/omnytraders"
              >
                Support Telegram
              </a>
            </p>

            <router-link
              :to="$localePath({ name: 'Login' })"
              class="btn btn-white-outline"
            >
              {{ $t("btn.cabinet") }}
            </router-link>
          </div>
        </div>
        <div class="footer__body">
          <div class="footer-nav">
            <div class="row row-gap-30">
              <div class="col-xl-12">
                <ul class="footer-main-menu row row-gap-30">
                  <li class="col-6 col-lg-3">
                    <router-link
                      :to="$localePath({ name: 'TradeTerms' })"
                      class="main-menu__item"
                    >
                      {{ $t("menu.terms") }}
                    </router-link>
                  </li>
                  <li class="col-6 col-lg-3 text-end text-lg-center">
                    <router-link
                      :to="$localePath({ name: 'Markets' })"
                      class="main-menu__item"
                    >
                      {{ $t("menu.markets") }}
                    </router-link>
                  </li>
                  <li class="col-6 col-lg-3 text-start text-lg-center">
                    <router-link
                      :to="$localePath({ name: 'About' })"
                      class="main-menu__item"
                    >
                      {{ $t("menu.about") }}
                    </router-link>
                  </li>
                  <li class="col-6 col-lg-3 text-end">
                    <router-link
                      :to="$localePath({ name: 'Contacts' })"
                      class="main-menu__item"
                    >
                      {{ $t("menu.contacts") }}
                    </router-link>
                  </li>
                </ul>
              </div>
              <div class="col-xl-12">
                <ul class="row row-gap-30 footer-nav__list list-inline">
                  <li class="col-6 col-lg-3">
                    <router-link
                      :to="$localePath({ name: 'Privacy', hash: '#tab3' })"
                    >
                      {{ $t("customer") }}
                    </router-link>
                  </li>
                  <li class="col-6 col-lg-3 text-end text-lg-center">
                    <router-link
                      :to="$localePath({ name: 'Privacy', hash: '#tab1' })"
                    >
                      {{ $t("privacy") }}
                    </router-link>
                  </li>
                  <li class="col-6 col-lg-3 text-start text-lg-center">
                    <router-link
                      :to="$localePath({ name: 'Privacy', hash: '#tab2' })"
                    >
                      {{ $t("refund") }}
                    </router-link>
                  </li>
                  <li class="col-6 col-lg-3 text-end">
                    <router-link :to="$localePath({ name: 'Faq' })">
                      {{ $t("subMenu.faq") }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer_bottom">
        <div class="container">
          <div class="footer-body__text">
            <p class="title">{{ $t("footerText.title") }}</p>
            <p class="title" v-html="$t('footerText.text')"></p>
          </div>

          <div class="footer__bottom">
            <p>{{ $t("footerCopy") }}</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
  i18n: {
    messages: {
      en: {
        footerText: {
          title: "Risk Warnings:",
          text: "Investing in financial instruments such as stocks, commodities, cryptocurrencies and others can provide an opportunity for high returns, but it also comes with certain risks. Before you start investing, it is important to study each instrument carefully. There is a possibility of losing your invested funds, so it is advisable to avoid the level of risk that you are not prepared for. You should also consider the possible risks associated with the use of leverage. <br/><br/>OMNY Traders. Registered office: 25 Dowgate Hill, London EC4R 2YA. Registered in England and Wales No. 2105. OMNY Traders plc is authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority under registration number 122479.",
        },
        footerCopy: "© 2024, OMNY Traders. All rights reserved",
      },
      ru: {
        footerText: {
          title: "Предупреждения о рисках:",
          text: "Инвестирование в финансовые инструменты, такие как акции, товары, криптовалюты и другие, может предоставить возможность для получения высоких доходов, но также сопряжено с определенными рисками. Перед тем как приступить к инвестированию, важно изучить каждый инструмент внимательно. Существует вероятность потери вложенных средств, поэтому рекомендуется избегать того уровня риска, на который вы не готовы. Также необходимо учитывать возможные риски, связанные с использованием кредитного плеча. <br/><br/>Мы настоятельно рекомендуем диверсифицировать инвестиционный портфель для снижения рисков и уделить внимание экономической обстановке и геополитическим факторам. OMNY Traders License number 00002105.",
        },
        footerCopy: "© 2024, OMNY Traders. Все права защищены",
      },
      fr: {
        footerText: {
          title: "Avertissements sur les risques:",
          text: "Investir dans des instruments financiers tels que les actions, les matières premières, les crypto-monnaies et autres peut permettre d'obtenir des rendements élevés, mais cela comporte également certains risques. Avant de commencer à investir, il est important d'étudier soigneusement chaque instrument. Il existe une possibilité de perdre les fonds investis, il est donc conseillé d'éviter le niveau de risque auquel vous n'êtes pas préparé. Vous devez également prendre en compte les risques éventuels liés à l'utilisation de l'effet de levier. <br/><br/>OMNY Traders. Siège social : 25 Dowgate Hill, London EC4R 2YA. Enregistré en Angleterre et au Pays de Galles sous le numéro 2105. OMNY Traders plc est autorisée par la Prudential Regulation Authority et réglementée par la Financial Conduct Authority et la Prudential Regulation Authority sous le numéro d'enregistrement 122479.",
        },
        footerCopy: "© 2024, OMNY Traders. Tous droits réservés",
      },
      de: {
        footerText: {
          title: "Risikowarnungen:",
          text: "Investitionen in Finanzinstrumente wie Aktien, Rohstoffe, Kryptowährungen und andere können die Chance auf hohe Renditen bieten, sind aber auch mit gewissen Risiken verbunden. Bevor Sie mit dem Investieren beginnen, ist es wichtig, dass Sie jedes Instrument sorgfältig studieren. Es besteht die Möglichkeit, dass Sie Ihr investiertes Geld verlieren, daher ist es ratsam, ein Risiko zu vermeiden, auf das Sie nicht vorbereitet sind. Sie sollten auch die möglichen Risiken bedenken, die mit dem Einsatz von Hebeln verbunden sind. <br/><br/>OMNY Traders. Eingetragener Sitz: 25 Dowgate Hill, London EC4R 2YA. Eingetragen in England und Wales unter der Nr. 2105. OMNY Traders plc ist von der Prudential Regulation Authority zugelassen und wird von der Financial Conduct Authority und der Prudential Regulation Authority unter der Registrierungsnummer 122479 reguliert.",
        },
        footerCopy: "© 2024, OMNY Traders. Alle Rechte vorbehalten",
      },
    },
  },
};
</script>

<style scoped lang="scss">
.footer {
  background: $secondary-color;
  color: $white;
}
.footer_bottom {
  background: $secondary-color-light;
}
.footer__top {
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    justify-content: center;
    gap: 30px;
  }
}
.footer-logo {
  img {
    width: auto;
    height: 40px;
  }
}
.footer-btn-block {
  display: flex;
  align-items: center;
  gap: 100px;

  @include media-breakpoint-down(lg) {
    gap: 50px;
  }
  @include media-breakpoint-down(lg) {
    gap: 30px;
    flex-direction: column;
    justify-content: center;
  }
}
.footer-nav {
  padding: 60px 0;
  border-top: 1px solid rgba($white, 0.1);
}
.footer-main-menu {
  border-bottom: 1px solid rgba($white, 0.1);
  padding-bottom: 30px;
}
.main-menu__item {
  text-transform: uppercase;
  font-weight: 700;
  color: $white;
}
.footer-nav__list {
  a {
    color: $white;
    &:hover {
      color: $white;
      text-decoration: underline;
    }

    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }
  }

  &.list-inline {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    @include media-breakpoint-down(md) {
      > li {
        width: calc(50% - 10px);
      }
    }
  }
}
.footer-body__text {
  padding: 40px 0;
  font-size: 14px;

  .title {
    margin-bottom: 16px;
  }
  .text {
    color: rgba($white, 0.6);
  }
}
.footer__bottom {
  border-top: 1px solid rgba($white, 0.1);
  padding: 30px 0 40px;
  font-size: 12px;
  color: rgba($white, 0.6);
}
</style>
